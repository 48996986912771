import "pure-react-carousel/dist/react-carousel.es.css"

import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel"
import BackgroundImage from "gatsby-background-image"
import { css, ClassNames } from "@emotion/core"
import { useTheme } from "emotion-theming"
import { mq, breakpoints } from "../../layouts/theme"
import { useDimensions } from "../../hooks/useDimensions"
import { ButtonLink } from "../../components/button-link"

export const Section1 = () => {
  const {
    newHeights,
    avilionWays,
    allInLiving,
    est1992,
    madeForYou,
  } = useStaticQuery(graphql`
    query {
      avilionWays: file(relativePath: { eq: "avilion-ways-to-live.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allInLiving: file(relativePath: { eq: "all-in-living.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      newHeights: file(relativePath: { eq: "new-heights.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      est1992: file(relativePath: { eq: "est-1992.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      madeForYou: file(relativePath: { eq: "made-for-you.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      taglineSig: file(relativePath: { eq: "tagline-signature.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const {
    colors: {
      bg: { primary, grey },
    },
  } = useTheme()
  const { ref: carouselSecRef, width, height } = useDimensions()
  const slides = [
    {
      img: avilionWays,
      header: "AVILION WAYS TO LIVE",
      caption:
        "In collaboration with master planner Palafox Associates, FSVRC aims to create communities that highlight green urbanism and boasts green design features.",
      link: "/projects/avilion-gardens",
    },
    {
      img: newHeights,
      header: "NEW HEIGHTS",
      caption:
        "First Shelter ensures that it is able to cater to a variety of users to offer them endless possibilities of living.",
      link: "/projects/altiplano",
    },
    {
      img: allInLiving,
      header: "ALL-IN LIVING",
      caption:
        "Creating a community is of top most priority to the company, which is why all projects are strategically planned to be fully equipped with all the needs of its users.",
      link: "/projects/bloomfield-resort",
    },
    {
      img: est1992,
      header: "EST. 1992",
      caption:
        "We spend enormous time on planning and market research to ensure that our products meet the high standards of our valued clients.",
      link: "/about",
      bgPosition: "bottom left",
    },
    {
      img: madeForYou,
      header: "MADE FOR YOU",
      caption:
        "FSVRC aims to cater to every type of client with our variety of projects. Let us know what you need and we’ll present you with options to get a step closer to your dream home.",
      link: "/contact",
    },
  ]
  return (
    <section
      css={css`
        height: 65vh;
        position: relative;
        max-height: 450px;
        ${mq.tablet} {
          height: 80vh;
        }
      `}
      ref={carouselSecRef}
    >
      <CarouselProvider
        visibleSlides={1}
        totalSlides={slides.length}
        step={1}
        naturalSlideWidth={width}
        naturalSlideHeight={height}
        lockOnWindowScroll
        infinite={true}
        isPlaying={true}
      >
        <Slider>
          {slides.map((v, idx) => {
            return (
              <Slide key={idx} index={idx}>
                <SlideSection {...v} />
              </Slide>
            )
          })}
        </Slider>
        <div
          css={css`
            bottom: 15px;
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
          `}
        >
          {slides.map((v, idx) => {
            return (
              <ClassNames key={idx}>
                {({ css }) => (
                  <Dot
                    key={idx}
                    slide={idx}
                    className={css`
                      background: ${grey};
                      border: 1px solid ${grey};
                      display: block;
                      height: 14px;
                      width: 14px;
                      border-radius: 50%;
                      margin: 0px 4px;
                      padding: 0;
                      &.carousel__dot--selected {
                        background: ${primary};
                        border-color: ${primary};
                      }
                      ${mq.tablet} {
                        height: 18px;
                        width: 18px;
                        margin: 0px 8px;
                      }
                    `}
                  />
                )}
              </ClassNames>
            )
          })}
        </div>
      </CarouselProvider>
    </section>
  )
}

const SlideSection = ({ img, bgPosition, header, caption, link }) => {
  return (
    <ClassNames>
      {({ css }) => (
        <BackgroundImage
          Tag="section"
          className={css`
            background-position: ${bgPosition
              ? bgPosition
              : "center"} !important;
            width: 100%;
            height: 100%;
            background-size: cover;
          `}
          fluid={img.childImageSharp.fluid}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 100%;
              width: 100%;
              max-width: ${breakpoints.lgDesktop}px;
              margin: 0 auto;
              padding-left: 25px;
              box-sizing: border-box;
              ${mq.mobile} {
                padding-left: 40px;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                max-width: 75%;
                margin-top: -10%;
                ${mq.mobile} {
                  max-width: 50%;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  box-sizing: border-box;
                  background: rgba(0, 0, 0, 0.5);
                  margin-bottom: 30px;
                  padding: 20px;
                  border-top-left-radius: 15px;
                  border-bottom-right-radius: 15px;
                  color: white;
                `}
              >
                <h2
                  css={css`
                    font-family: ProximaNova-Bold;
                    letter-spacing: 2.5px;
                    color: white;
                    margin: 0;
                  `}
                >
                  {header}
                </h2>
                <h5
                  css={css`
                    font-family: ProximaNova-Semibold;
                    margin-top: 20px;
                    letter-spacing: 1px;
                    color: white;
                  `}
                >
                  {caption}
                </h5>
              </div>
              <div>
                <ButtonLink to={link} label="TAKE A LOOK" />
              </div>
            </div>
          </div>
        </BackgroundImage>
      )}
    </ClassNames>
  )
}
