import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import { useTheme } from "emotion-theming"
import Img from "gatsby-image"

export const Section2 = () => {
  const { taglineSig } = useStaticQuery(graphql`
    query {
      taglineSig: file(relativePath: { eq: "tagline-signature.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const {
    colors: {
      bg: { grey },
    },
  } = useTheme()
  return (
    <section
        css={css`
          background: ${grey};
          padding: 45px 25px;
        `}
      >
        <div
          css={css`
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 100%;
            max-width: 550px;
            height: 100%;
          `}
        >
          <h2
            css={css`
              font-family: ProximaNova-ExtraBold;
              letter-spacing: 2px;
            `}
          >
            LIVE BETTER
          </h2>
          <h6
            css={css`
              font-family: ProximaNova-Regular;
              letter-spacing: 1.5px;
              line-height: 1.3;
            `}
          >
            We are committed to providing attainable and affordable homes for
            people that are on the lookout for their next dream home. We boast
            our communities that are all-providing and equipped with
            well-designed homes. The choice to live better is yours. With us, we
            give you-
          </h6>
          <div
            css={css`
              width: 80%;
            `}
          >
            <Img fluid={taglineSig.childImageSharp.fluid} />
          </div>
        </div>
      </section>
  )
}