import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import { useMediaQuery } from "react-responsive"
import { mq, breakpoints } from "../../layouts/theme"
import Img from "gatsby-image"
import { useTheme } from "emotion-theming"
import { ButtonLink } from "../../components/button-link"

export const Section3 = () => {
  const {
    colors: {
      txt: { darkGrey },
    },
  } = useTheme()
  const {
    alseaP,
    avilionP,
    altiplanoP,
    bloomFieldParkP,
    alseaL,
    avilionL,
    altiplanoL,
    bloomFieldParkL,
  } = useStaticQuery(graphql`
    fragment ImgP on File {
      childImageSharp {
        fixed(width: 770) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fragment ImgL on File {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      alseaP: file(relativePath: { eq: "alsea-perspective.jpg" }) {
        ...ImgP
      }
      avilionP: file(relativePath: { eq: "avilion-perspective.jpg" }) {
        ...ImgP
      }
      altiplanoP: file(relativePath: { eq: "altiplano-perspective.jpg" }) {
        ...ImgP
      }
      bloomFieldParkP: file(relativePath: { eq: "bf-park-perspective.jpg" }) {
        ...ImgP
      }
      alseaL: file(relativePath: { eq: "alsea-logo.png" }) {
        ...ImgL
      }
      avilionL: file(relativePath: { eq: "avilion-gardens-logo.png" }) {
        ...ImgL
      }
      altiplanoL: file(relativePath: { eq: "altiplano-logo.png" }) {
        ...ImgL
      }
      bloomFieldParkL: file(relativePath: { eq: "bf-park-logo.png" }) {
        ...ImgL
      }
    }
  `)
  const isMobile = useMediaQuery({ minWidth: breakpoints.mobile })
  const isTablet = useMediaQuery({ minWidth: breakpoints.tablet })
  return (
    <section
      css={css`
        display: grid;
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        ${mq.mobile} {
          grid-template-columns: repeat(2, 1fr);
          grid-auto-rows: 1fr;
        }
        ${mq.tablet} {
          grid-template-columns: repeat(4, 1fr);
          grid-auto-rows: 1fr;
        }
      `}
    >
      {[
        {
          name: "altiplano",
          logoWidth: 120,
          perspective: altiplanoP,
          logo: altiplanoL,
          desc:
            "A community within a community, Altiplano is a two building, 5-storey condominium situated inside Avilion Gardens, Parañaque City. ",
        },
        {
          name: "avilion-gardens",
          perspective: avilionP,
          logo: avilionL,
          logoWidth: 100,
          desc:
            "Master planned by Palafox Associates, lies a life of endless possibilities in Parañaque. At Avilion Gardens, experience avilion ways to live. ",
        },
        {
          name: "bloomfield-park",
          perspective: bloomFieldParkP,
          logo: bloomFieldParkL,
          desc:
            "Located in Mabalacat, Pampanga, Bloomfield is the ideal community for start-up families and young working individuals who are looking for a home or investment.",
        },
        {
          name: "alsea",
          perspective: alseaP,
          logo: alseaL,
          logoWidth: 135,
          link: "",
          desc:
            "Engulfed in lush greens is a townhouse development sitting in Parañaque City. Offering elegant and stylish modern design, Alsea is the future of affordable living.",
        },
      ].map(({ perspective, logo, desc, logoWidth, name }, i) => {
        const dividend = i
        // TODO: react-media-query
        const divisor = isTablet ? 2 : isMobile ? 1 : 0
        const answer = Math.floor(dividend / divisor)
        const typ = answer % 2 === 0 ? "even" : "odd"
        const img = (
          <div
            css={css`
              height: 250px;
              ${mq.mobile} {
                height: initial;
              }
            `}
          >
            <Img
              style={{ width: "100%", height: "100%" }}
              fixed={perspective.childImageSharp.fixed}
              objectFit="cover"
              objectPosition="150% 150%"
              alt=""
            />
          </div>
        )
        const content = (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 35px 30px;
              box-sizing: border-box;
            `}
          >
            <div
              css={css`
                width: ${logoWidth ? logoWidth : 180}px;
                margin-bottom: 20px;
              `}
            >
              <Img fluid={logo.childImageSharp.fluid} />
            </div>
            <p
              css={css`
                text-align: center;
                margin-bottom: 25px;
                color: ${darkGrey};
                letter-spacing: 1px;
              `}
            >
              {desc}
            </p>
            <ButtonLink to={"/projects/" + name } label="CHECK OUT" />
          </div>
        )
        if (typ === "even") {
          return (
            <React.Fragment key={i}>
              {img}
              {content}
            </React.Fragment>
          )
        }
        if (typ === "odd") {
          return (
            <React.Fragment key={i}>
              {content}
              {img}
            </React.Fragment>
          )
        }
        return null
      })}
    </section>
  )
}
