import React from "react"
import SEO from "../components/seo"
import { ContactUs } from "../page-components/contact/contact-us"
import { Section1 } from "../page-components/home/section-1"
import { Section2 } from "../page-components/home/section-2"
import { Section3 } from "../page-components/home/section-3"


// FBCHAT: https://spectrum.chat/gatsby-js/general/add-facebook-messenger-customer-chat-to-gatsbyjs-project~bf959547-b12f-4239-88c5-00f0ef5b3782
const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <Section1 />
      <Section2 />
      <Section3 />
      <ContactUs />
    </>
  )
}

export default IndexPage
