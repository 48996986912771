import { useRef, useState, useEffect } from "react"

export const useDimensions = () => {
  const ref = useRef()
  const [{ width, height }, setProportion] = useState({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    function updateDimensions() {
      if (ref && ref.current) {
        const node = ref.current
        setProportion({
          height: node?.clientHeight,
          width: node?.clientWidth,
        })
      }
    }
    updateDimensions()
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [ref])
  return {
    ref,
    width,
    height,
  }
}
